import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router'
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import { useForm, Controller } from 'react-hook-form';
import { BASENAME_URL_LOGIN, BASENAME_URL_OPPORTUNITIES } from "constants/settings";
import {
    getIsUserAuthenticated,
    getUserTypeID
} from "selectors/user";
import { EMAIL_REGEXP, intlMessages, isDefined } from 'services/util/auxiliaryUtils';
import IntlMessages from 'components/util/IntlMessages';
import Loading from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';

import { ReactComponent as BackgroundLeft } from 'assets/images/auth/svg/graphic-left.svg';
import { ReactComponent as BackgroundRight } from 'assets/images/auth/svg/graphic-right.svg';
import { ReactComponent as PlatformLogo } from 'assets/images/logos/svg/eff-platform.svg';
import useRecoveryPassword from 'hooks/recoveryPassword';





const ResetPassword = ({
    userTypeID,
    isUserAuthenticated
}) => {

    let navigate = useNavigate();

    const { fetchResetPassword, isRequesting, msgResetPassword } = useRecoveryPassword();

    const {
        handleSubmit,
        control,
        formState: {
            errors,
        },
    } = useForm({
        defaultValues: {
            email: ''
        },
    });


    // componentWillUnmountWithUseEffect
    useEffect(() => {
        return () => {
            toast.dismiss(); // dismiss all notifications
        }
    }, []); //eslint-disable-line

    // if user is authenticated, redirect
    useEffect(() => {
        if (isUserAuthenticated && isDefined(userTypeID)) {
            navigate(BASENAME_URL_OPPORTUNITIES);
        }
    }, [isUserAuthenticated]); //eslint-disable-line

    //handleOnSubmit
    const handleOnSubmit = ({ email }) => {
        fetchResetPassword(email.toLowerCase());
    };



    return (
        <div className={'app-main-login'}>

            <section className="login-svg-bg">
                <div className="login-svg-left">
                    <BackgroundLeft />
                </div>
                <div className={"login-svg-right"}>
                    <BackgroundRight />
                </div>
            </section>

            <section className="login-top-section">

                <div className="app-login-container animated fadeIn animation-duration-2">
                    <div className="login-content m-4">
                        {/* Header */}
                        <div className='login-header'>
                            <PlatformLogo />
                        </div>
                        {/* Body */}
                        <div className="login-body">
                            <div className="login-body-text">
                                <h4><IntlMessages id={'page.resetPassword.subtitle'} /></h4>
                            </div>

                            {/* ResetPassword */}
                            <form autoComplete={'false'} onSubmit={handleSubmit(handleOnSubmit)}>
                                <div className="row">
                                    {/* email */}
                                    <div className="col-12">
                                        <div className="d-flex flex-column mt-2 mb-3" >
                                            <Controller
                                                name="email"
                                                control={control}
                                                rules={{
                                                    required: "yup.message.required",
                                                    pattern: {
                                                        value: EMAIL_REGEXP,
                                                        message: 'yup.message.email'
                                                    }
                                                }}
                                                disabled={isRequesting}
                                                render={({ field }) => (
                                                    <OutlinedInput
                                                        {...field}
                                                        placeholder={intlMessages('placeholder.emailExample')}
                                                        disabled={isRequesting}
                                                        error={errors?.email}
                                                    />)}
                                            />
                                            {errors?.email && <ErrorMessage error={errors.email} />}
                                            {!!msgResetPassword && <p className="text-center text-info p-0 m-0 mt-3"><IntlMessages id={msgResetPassword} /></p>}
                                        </div>
                                    </div>
                                </div>
                                {/*actions*/}
                                <div className="mt-4 mb-3 d-flex justify-content-center">
                                    <Button
                                        disabled={isRequesting}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        <IntlMessages id={'label.resetPassword'} />
                                        {isRequesting && <Loading size={24} className="position-absolute" />}
                                    </Button>
                                </div>
                            </form>

                            {/*Go to Login*/}
                            <div className="link-back-login d-flex justify-content-center">
                                <h4>
                                    <Link to={`${BASENAME_URL_LOGIN}`} className={isRequesting ? 'disabled-cursor' : ''}>
                                        <IntlMessages id='label.backLogin' />
                                    </Link>
                                </h4>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* <Footer isUserAuthenticated={isUserAuthenticated} /> */}
        </div>
    );
};


const mapStateToProps = ({ user }) => {
    return {
        userTypeID: getUserTypeID(user),
        isUserAuthenticated: getIsUserAuthenticated(user),
    }
};

export default connect(mapStateToProps, {})(ResetPassword);

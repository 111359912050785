import React, { memo } from 'react';
import PropTypes from 'prop-types';

//Material UI
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

//Our Components
import IntlMessages from 'components/util/IntlMessages';
import { ReactComponent as AvatarIcon } from 'assets/images/icons/svg/avatar.svg';
import { ReactComponent as GoToIcon } from "assets/images/icons/svg/go-to.svg";
import { ReactComponent as ArrowRedIcon } from "assets/images/icons/svg/arrow-primary-color.svg";
import { getUserType } from 'services/user';




const UserCard = ({
    isOpen,
    isThisPathActive,
    handleToggle,
    handleEditUserData,
    handleUserSignOut,
    userTypeID,
    username,
    setDarkTheme,
}) => {

    return (
        <Dropdown
            isOpen={isOpen}
            toggle={handleToggle}>

            <DropdownToggle
                className="header-dropdown-toggle"
                tag="span"
                data-toggle="dropdown"
            >
                <div className="menu-item-content-last">
                    <div className={`default-svg${isOpen || isThisPathActive ? "--open" : ""}`}>
                        <AvatarIcon height={24} />
                    </div>
                </div>
            </DropdownToggle>

            <DropdownMenu end>
                <div className="dropdown-header-section user-card">
                    <div className="header-section-text">
                        <h5 className="mb-0">
                            {username}
                        </h5>
                        <h6 className='mb-0 mt-1'>
                            {getUserType(userTypeID)}
                        </h6>
                    </div>
                    <div className="invisible">
                        {/* Set Dark Theme */}
                        <div className="p-2" onClick={setDarkTheme}>
                            <span>
                                <i className="zmdi zmdi-lamp dark-mode-icon" />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="dropdown-header-section user-card">
                    <div className="d-flex flex-fill align-items-center justify-content-between">
                        <div className="tertiary-button">
                            <span
                                onClick={handleEditUserData}
                            >
                                <ArrowRedIcon className="me-1" />
                                <IntlMessages id="label.userData" />
                            </span>
                        </div>
                        <div className="tertiary-button">
                            <span
                                onClick={handleUserSignOut}
                            >
                                <GoToIcon className="me-1" />
                                <IntlMessages id="label.logout" />
                            </span>
                        </div>
                    </div>
                </div>
            </DropdownMenu>
        </Dropdown>
    )
}


//PropTypes
UserCard.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isThisPathActive: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    handleUserSignOut: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired,
    userTypeID: PropTypes.number.isRequired,
    setDarkTheme: PropTypes.func.isRequired
}
export default memo(UserCard);
//Libraries
import { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router';

//MUI
import { Button } from '@mui/material';

//Components
import IntlMessages from 'components/util/IntlMessages';

//Icons
import { ReactComponent as BrokenLightBulb } from "assets/images/icons/svg/error404/broken-lightbulb.svg";
import { ReactComponent as BackgroundLeft } from 'assets/images/icons/svg/error404/left-graph.svg';
import { ReactComponent as BackgroundRight } from 'assets/images/icons/svg/error404/right-graph.svg';
import { ReactComponent as BackgroundBottom } from 'assets/images/icons/svg/error404/bottom-graph.svg';

//Constants
import { BASENAME_URL, BASENAME_URL_ADFS_TOKEN_EXPIRY } from 'constants/settings';

const Error404 = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const isADFSFaulire = useMemo(() => (location?.pathname === BASENAME_URL_ADFS_TOKEN_EXPIRY), [location])

    return (
        <div className="content-wrapper-fluid page404-container">

            <div className="ornaments-svg">
                <div className="ornament-left">
                    <BackgroundLeft />
                </div>
                <div className="ornament-bottom-container">
                    <div className="ornament-bottom">
                        <BackgroundBottom />
                    </div>
                </div>
                <div className="ornament-right">
                    <BackgroundRight />
                </div>
            </div>

            <section className="page404-content-container">
                <div className="content-container">
                    <div className="page404-content-image">
                        <BrokenLightBulb />
                    </div>
                    <div className="page404-content-text">
                        <h3 className="text-center mt-3">
                            <IntlMessages id={isADFSFaulire ? 'page.404.description.adfs' : 'page.404.description.part1'} />
                        </h3>
                        {!isADFSFaulire &&
                            <span className="text-center"><IntlMessages id="page.404.description.part2" /></span>
                        }
                    </div>

                </div>
                <div className="w-100 text-center">
                    <Button
                        disableRipple
                        variant="contained"
                        color="primary"
                        className="go-home-button"
                        onClick={() => navigate(isADFSFaulire ? BASENAME_URL_ADFS_TOKEN_EXPIRY : BASENAME_URL)}

                    >
                        <IntlMessages id={isADFSFaulire ? 'page.401.adfs.button' : 'page.404.button'} />
                    </Button>
                </div>

            </section>
        </div>
    )
}

export default Error404;

import { ReactComponent as UnselectedRadioIcon } from 'assets/images/icons/svg/mui/radio/unselected-radio.svg';
import { ReactComponent as UnselectedRadioHoverIcon } from 'assets/images/icons/svg/mui/radio/unselected-hover-radio.svg';
import { ReactComponent as SelectedRadioIcon } from 'assets/images/icons/svg/mui/radio/selected-radio.svg';
import { ReactComponent as SelectedDisabledRadioIcon } from 'assets/images/icons/svg/mui/radio/selected-disabled-radio.svg';
import { ReactComponent as SelectedHoveredRadioIcon } from 'assets/images/icons/svg/mui/radio/selected-hover-radio.svg';
// Select
import { ReactComponent as DropdownArrowSelectIcon } from 'assets/images/icons/svg/mui/select/arrow-icon.svg';
// Checkbox
import { ReactComponent as UnselectedCheckboxIcon } from 'assets/images/icons/svg/mui/checkbox/unselected-checkbox.svg';
import { ReactComponent as UnselectedCheckboxHoverIcon } from 'assets/images/icons/svg/mui/checkbox/unselected-hovered-checkbox.svg';
import { ReactComponent as SelectedCheckboxIcon } from 'assets/images/icons/svg/mui/checkbox/selected-checkbox.svg';
import { ReactComponent as SelectedDisabledCheckboxIcon } from 'assets/images/icons/svg/mui/checkbox/selected-disabled-checkbox.svg';
import { ReactComponent as SelectedHoveredCheckboxIcon } from 'assets/images/icons/svg/mui/checkbox/selected-hovered-checkbox.svg';

//* Material UI component styling overrides *\\
const muiOverrides = {
	components: { // Default props to any component
		"MuiInputLabel": {
			"root": {
				"width": "calc(100% - 50px)",
				"white-space": "nowrap",
				"overflow": "hidden",
				"text-overflow": "ellipsis"
			},
			"shrink": {
				"width": "unset",
				"white-space": "unset",
				"overflow": "unset",
				"text-overflow": "unset"
			}
		},
		MuiSwitch: {
			defaultProps: {
				color: 'default',
				disableRipple: true
			},
			styleOverrides: {
				root: {
					padding: '9px'
				},
				track: {    // when switched off (unchecked)
					borderRadius: '15px',
					border: '1px solid rgb(196, 196, 196)',
					backgroundColor: 'rgb(220, 220, 220)',
					opacity: 1
				},
				thumb: {
					boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.08)',
					backgroundColor: 'var(--paper-color)',
					border: '1px solid rgb(196, 196, 196)'
				},
				switchBase: { // when switched on (checked)
					top: '5px',
					left: '5px',
					padding: '4px',
					'&:hover': {
						backgroundColor: 'rgba(155, 220, 202, 0.4)'
					},
					"&.Mui-checked + .MuiSwitch-track": {
						backgroundColor: 'rgba(62, 192, 156, 0.5)',
						border: '1px solid rgb(62, 192, 156)',
						opacity: 1
					},
					'&$disabled': {
						'& + $track': {
							backgroundColor: 'rgb(229, 229, 229)',
							opacity: 1
						},
						'& $thumb': {
							backgroundColor: 'rgb(229, 229, 229)',
							boxShadow: 'none'
						},
					}
				}
			}
		},
		MuiRadio: {
			defaultProps: {
				style: {
					backgroundColor: 'transparent'
				},
				disableRipple: true,
				checkedIcon: <>
					<SelectedRadioIcon className="selected-radio-icon" />
					<SelectedDisabledRadioIcon className="selected-radio-icon--disabled" />
					<SelectedHoveredRadioIcon className="selected-radio-icon--hovered" />
				</>,
				icon: <>
					<UnselectedRadioIcon className="unselected-radio-icon" />
					<UnselectedRadioHoverIcon className="unselected-radio-icon--hovered" />
				</>
			},
			styleOverrides: {
				root: {
					'& .unselected-radio-icon--hovered': {
						display: 'none'
					},
					'& .selected-radio-icon--hovered': {
						display: 'none'
					},
					'& .selected-radio-icon--disabled': {
						display: 'none'
					},
					'&:hover': {    // Unchecked (and enabled) radio button on hover
						'& .unselected-radio-icon--hovered': {
							display: 'initial'
						},
						'& .unselected-radio-icon': {
							display: 'none'
						},
					},
					'&$checked:hover': {  // Checked (and enabled) radio button on hover
						'& .selected-radio-icon--hovered': {
							display: 'initial'
						},
						'& .selected-radio-icon': {
							display: 'none'
						}
					},
					'&$checked$disabled': { // Checked and disabled radio button
						'& .selected-radio-icon--disabled': {
							display: 'initial'
						},
						'& .selected-radio-icon': {
							display: 'none'
						}
					}
				}
			}
		},
		MuiSelect: {
			defaultProps: {
				IconComponent: DropdownArrowSelectIcon,
				MenuProps: { // Props passed to dropdown menu
					// Anchor menu to bottom part of select
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'center'
					},
					// getContentAnchorEl: null,
					MenuListProps: {
						disablePadding: true
					},
					classes: {
						paper: 'mui-select-menu-paper'
					}
				}
			},
			styleOverrides: {
				root: {
					height: '40px',
					color: '#a3a3a3',
					opacity: '1',
					backgroundColor: 'var(--paper-color)',
					'&$disabled': {
						backgroundColor: 'rgb(245, 245, 245)'
					},
					fontSize: '0.875rem'
				},
				icon: {
					top: 'calc(50% - 4px)',
					'&$disabled': {
						fill: 'rgb(163, 163, 163)'
					}
				},
				iconOutlined: {
					right: '14px'
				},
				outlined: {
					paddingRight: '2.5rem !important'
				},
				select: {
					fontSize: '0.875rem',
					'& .select-placeholder': {
						color: 'rgb(163, 163, 163)'
					}
				}
			}
		},
		MuiMenuItem: {
			defaultProps: {
				disableRipple: true
			},
			styleOverrides: {
				root: {
					borderTop: '1px solid rgb(196, 196, 196)',
					minHeight: '40px !important',
					fontSize: '0.875rem',
					'&:hover': {
						backgroundColor: 'var(--primary-color) !important',
						color: 'var(--paper-color) !important'
					},
					'&:active': {
						backgroundColor: 'var(--primary-color-dark) !important',
						color: 'var(--paper-color)'
					}
				}
			}
		},
		MuiPopover: {
			defaultProps: {
				elevation: 3
			}
		},
		MuiCheckbox: {
			defaultProps: {
				style: {
					backgroundColor: 'transparent'
				},
				checkedIcon: <>
					<SelectedCheckboxIcon className="selected-checkbox-icon" />
					<SelectedDisabledCheckboxIcon className="selected-checkbox-icon--disabled" />
					<SelectedHoveredCheckboxIcon className="selected-checkbox-icon--hovered" />
				</>,
				icon: <>
					<UnselectedCheckboxIcon className="unselected-checkbox-icon" />
					<UnselectedCheckboxHoverIcon className="unselected-checkbox-icon--hovered" />
				</>
			},
			styleOverrides: {
				root: {
					'& .unselected-checkbox-icon--hovered': {
						display: 'none'
					},
					'& .selected-checkbox-icon--hovered': {
						display: 'none'
					},
					'& .selected-checkbox-icon--disabled': {
						display: 'none'
					},
					'&:hover': {    // Unchecked (and enabled) checkbox button on hover
						'& .unselected-checkbox-icon--hovered': {
							display: 'initial'
						},
						'& .unselected-checkbox-icon': {
							display: 'none'
						},
					},
					'&$checked:hover': {  // Checked (and enabled) checkbox button on hover
						'& .selected-checkbox-icon--hovered': {
							display: 'initial'
						},
						'& .selected-checkbox-icon': {
							display: 'none'
						}
					},
					'&$checked$disabled': { // Checked and disabled checkbox button
						'& .selected-checkbox-icon--disabled': {
							display: 'initial'
						},
						'& .selected-checkbox-icon': {
							display: 'none'
						}
					}
				}
			}
		},
		MuiButton: {
			defaultProps: {
				disableRipple: true
			},
			styleOverrides: {
				root: {
					height: 40,
					padding: "0.5rem 1rem",
					fontWeight: 500,
					fontSize: "1rem",
					borderRadius: 5,
					lineHeight: '1rem',
					'@media (max-width: 992px), (max-height: 767.98px)': {
						height: 35,
						padding: "10px 25px",
					},
					'@media (max-width: 768px)': {
						height: 30,
						padding: "10px 20px",
					},
					'& .MuiButton-label': {
						lineHeight: '1rem'
					},
				},
				text: {
					height: 52,
					padding: "12px 30px",
					color: 'var(--text-color)',
				},
				containedPrimary: {
					color: '#FFF',
					'&:hover': {
						backgroundColor: 'var(--primary-color-light)'
					},
					":not(.Mui-disabled)": {
						color: "var(--paper-color) !important",
						"& svg path": {
							fill: "var(--paper-color) !important",
							transition: "cubic-bezier(0.4, 0, 0.2, 1), fill 250ms",
						},
					},
				},
				outlinedPrimary: {
					":not(.Mui-disabled)": {
						border: '2px solid var(--primary-color) !important',
					},
					'&:hover': {
						border: '2px solid var(--primary-color-light)',
						//backgroundColor: 'var(--paper-color)'
					},
					"&$disabled": {
						backgroundColor: '#F5F5F5'
					}
				},
			}
		},
		MuiAvatar: {
			styleOverrides: {
				colorDefault: {
					backgroundColor: 'var(--primary-color)'
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				rounded: {
					borderRadius: '8px'
				},
				root: {
					'&.mui-select-menu-paper': {
						maxHeight: '240px', // 6 x 40px (MuiMenu Height)
						overflowY: 'auto',
						borderRadius: '0 0 8px 8px',
						border: '1px solid rgb(196, 196, 196)'
					},
					'& > .MuiDialogContent-root > div > .PrivatePickersToolbar-root': {
						backgroundColor: 'var(--primary-color)',
					}
				}
			}
		},
		MuiOutlinedInput: {
			defaultProps: {
				notched: false,
				label: null
			},
			styleOverrides: {
				root: {
					color: "var(--text-color) !important",
					minHeight: '40px !important',
					textOverflow: 'ellipsis',
					padding: 'unset !important',
					'&:hover $notchedOutline': {
						border: "2px solid rgba(196, 196, 196, 1)",
						borderColor: "rgba(196, 196, 196, 1)"
					},
					'&.Mui-disabled': {
						'& .MuiOutlinedInput-notchedOutline': {
							border: '1px solid rgba(0, 0, 0, 0.23)'
						}
					},
					'&.Mui-focused': {
						'& .MuiOutlinedInput-notchedOutline': {
							border: '2px solid black !important'
						}
					}
				},
				input: {
					padding: '7.5px 0.75rem !important',
				},
				adornedEnd: {
					paddingRight: '0.875rem'
				},
				inputAdornedEnd: {
					paddingRight: '0.75rem'
				}
			}
		},
		MuiInputAdornment: {
			styleOverrides: {
				root: {
					marginRight: '1rem'
				}
			},
			defaultProps: {
				position: 'end'
			}
		},
		MuiSlider: {
			styleOverrides: {
				root: {
					margin: '1rem 0',
					height: '1px'
				},
				thumb: {
					width: '17px !important',
					height: '17px !important',
					marginTop: '0px !important',
					marginLeft: '0px !important'
				},
				valueLabel: {
					backgroundColor: 'transparent',
					top: '0px',
					left: 'initial',
					'& > span': {
						color: 'var(--text-color)',
						fontSize: '14px',
						width: 'inherit'
					}
				},
				rail: {
					backgroundColor: 'gray'
				},
				mark: {
					backgroundColor: 'gray',
					height: '1px',
					width: '1px'
				}
			}
		},
		MuiTablePagination: {
			styleOverrides: {
				toolbar: {
					minHeight: "40px",
					display: 'flex',
					alignItems: 'center',
					'@media (max-width: 992px), (max-height: 767.98px)': {
						"& .MuiIconButton-root": {
							padding: "5px 5px",
							margin: "0 7px",
							"& .MuiIconButton-label": {
								height: "24px",
								width: "24px"
							}
						}
					}
				},
				selectLabel: {
					margin: 0
				}
			}
		},
		MuiAutocomplete: {
			defaultProps: {
				popupIcon: <DropdownArrowSelectIcon />
			},
			styleOverrides: {
				inputRoot: {
					padding: '7.5px 39px 7.5px 0.75rem !important',
					display: 'flex',
					alignItems: 'flex-end'
				},
				input: {
					padding: '0 !important',
					alignSelf: 'center'
				},
				listbox: {
					backgroundColor: 'var(--paper-color)',
					fontSize: 14,
					overflowX: 'hidden',
					width: '100%',
					padding: '0 !important',
					maxHeight: '200px !important',
					overflowY: 'auto',

					'& .MuiAutocomplete-option': {
						height: '40px',
						fontSize: 14,
						borderLeft: '1px solid #C4C4C4',
						borderBottom: '1px solid #C4C4C4',
						backgroundColor: 'var(--paper-color)',

						'&:hover': {
							backgroundColor: 'var(--primary-color)',
							color: 'var(--paper-color)'
						}
					}
				},
				endAdornment: {
					right: '0.875rem !important'
				},
			}
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					minHeight: '40px',
					padding: '4px 0 5px !important'
				},
				input: {
					fontSize: '0.875rem',
				},
				'& .MuiOutlinedInput-input': {
					padding: '7.5px 39px 7.5px 0.75rem !important',
				}
			}
		}
	}
}

export default muiOverrides;